import React, { useEffect, useState } from 'react';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import css from '@styled-system/css';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { compose } from 'ramda';
import { Flex } from 'reflexbox/styled-components';

import { tenantServerProps } from 'libs/tenant-server-props';
import { userRoleHomeRoute } from 'providers/auth/utils';
import { useAccount } from 'providers/auth0';
import { useI18n } from 'providers/i18n/use-i18n';

import type { GetServerSideProps, NextPage } from 'next';

const LoginPage = () => {
    const { i18n } = useI18n();

    const { isAuthenticated } = useAuth0();
    const { role } = useAccount();

    const router = useRouter();
    const { applicationId, referral, transactionType } = router.query;

    const [isRedirecting, setIsRedirecting] = useState(false);

    useEffect(() => {
        // in order to avoid calling router.push multiple times
        // we check if we already in the process of redirecting
        if (isAuthenticated && role && !isRedirecting) {
            setIsRedirecting(true);

            if (referral === 'true') {
                router.push({
                    pathname: `/applications/internal-referral`,
                    query: {
                        transactionType,
                        ...(applicationId && { applicationId }),
                    },
                });
            } else {
                router.push(userRoleHomeRoute[role] || '/');
            }
        }
    }, [
        isAuthenticated,
        role,
        router,
        isRedirecting,
        referral,
        transactionType,
        applicationId,
    ]);

    return (
        <Flex
            css={css({
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
            })}
        >
            <Head>
                <title>{i18n._('login')}</title>
            </Head>
            <Flex>...waiting for redirection...</Flex>
        </Flex>
    );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
    return tenantServerProps(context);
};

// it may look weird to have the withAuthenticationRequired HOC here
// but we want the user to be redirected to the Auth0 login page
// if they are not authenticated
// then come back here to be redirected to the right dashboard page
const Page: NextPage = compose(withAuthenticationRequired)(LoginPage);

export default Page;
